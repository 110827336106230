<template>
  <div class="wrapper">
    <AppHeader />
    <Suspense>
      <template #default>
        <ReadShow :id="id" />
      </template>
      <template #fallback>
        <Spinner />
      </template>
    </Suspense>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Spinner from "@/components/Spinners/Spinner.vue";
const ReadShow = defineAsyncComponent(() =>
  import('@/components/Pages/ReadShow.vue')
)
export default {
  name: "ReadArticle",
  props: {
    id: String,
  },
  components: {
    AppHeader,
    AppFooter,
    ReadShow,
    Spinner,
    Suspense,
  },
};
</script>

<style>
</style>